import React from "react"


export type ProjectData = {
  title: string
  company: string
  location: string
  body: string
}


type DefaultValueType = {
  data: {
    RE: ProjectData
    AM: ProjectData
  }
  active: {
    RE: string | false
    AM: string | false
  }
  container: number
  setData: (type: string, data: ProjectData) => void
  setActive: (type: string, data: string | false) => void
  setContainer: (value: number) => void
}

const defaultValue: DefaultValueType = {
  data: {

    RE: { title: "", company: "", location: "", body: "" },
    AM: { title: "", company: "", location: "", body: "" },
  },
  active: {
    RE: false,
    AM: false,
  },
  container: 0,
  setData: () => {},
  setActive: () => {},
  setContainer: () => {},
}

export const AppContext = React.createContext<DefaultValueType>(defaultValue)

interface Props {
  children: React.ReactNode
}

const AppProvider = ({ children }: Props) => {
  const [data, setData] = React.useState<{ RE: ProjectData; AM: ProjectData }>({

    RE: { title: "", company: "", location: "", body: "" },
    AM: {
      title: "",
      company: "",
      location: "",
      body: "",
    },
  })
  const [active, setActive] = React.useState<{
    RE: string | false
    AM: string | false
  }>({
    RE: false,
    AM: false,
  })
  const [container, setContainer] = React.useState<number>(0)

  return (
    <AppContext.Provider
      value={{
        data,
        active,
        container,
        setData: (type, data) => {
          type.toLowerCase() === "re" &&
            setData(previous => ({ ...previous, RE: data }))
          type.toLowerCase() === "am" &&
            setData(previous => ({ ...previous, AM: data }))
        },
        setActive: (type, data) => {
          type.toLowerCase() === "re" &&
            setActive(previous => ({ ...previous, RE: data }))
          type.toLowerCase() === "am" &&
            setActive(previous => ({ ...previous, AM: data }))
        },
        setContainer,
      }}
    >
      {children}
    </AppContext.Provider>
  )
}

export default ({ element }) => {
  return <AppProvider>{element}</AppProvider>
}
