module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-catch-links/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-smoothscroll/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"reamc-limited","short_name":"reamc-limited","start_url":"/","background_color":"#FFFFFF","theme_color":"#515396","display":"minimal-ui","icon":"src/images/Reamc-icon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"288af5931aac1ff11d1bf08e5fc42d36"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
