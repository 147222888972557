// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-tsx": () => import("./../../../src/pages/About.tsx" /* webpackChunkName: "component---src-pages-about-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-press-releases-tsx": () => import("./../../../src/pages/Press-Releases.tsx" /* webpackChunkName: "component---src-pages-press-releases-tsx" */),
  "component---src-pages-projects-tsx": () => import("./../../../src/pages/Projects.tsx" /* webpackChunkName: "component---src-pages-projects-tsx" */),
  "component---src-pages-publications-tsx": () => import("./../../../src/pages/Publications.tsx" /* webpackChunkName: "component---src-pages-publications-tsx" */),
  "component---src-templates-conferences-tsx": () => import("./../../../src/templates/conferences.tsx" /* webpackChunkName: "component---src-templates-conferences-tsx" */),
  "component---src-templates-reports-tsx": () => import("./../../../src/templates/reports.tsx" /* webpackChunkName: "component---src-templates-reports-tsx" */)
}

